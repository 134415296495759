ready( function() {
	return;
	let lists = [...document.querySelectorAll('[data-sort]')];
	
	lists
	.filter( function(el) {
		return el.dataset.sort == 'alphabetic';
	})
	.forEach( function(list) {
		let items = [...list.querySelectorAll('li')];
		items = items.sort( function(a, b) {
			return (a.textContent.toLowerCase() < b.textContent.toLowerCase())
				? -1
				: (a.textContent.toLowerCase() > b.textContent.toLowerCase())
					? 1
					: 0;

		});
		list.innerHTML = "";
		items.forEach( function(li) {
			list.appendChild(li);
		});
	});
});