ready( function() {
	var selector = '[data-typing]';
	var el = document.querySelector(selector);
	var strings = el.dataset.typing.split(';');
	var typed = new Typed(selector, {
		strings: strings,
		typeSpeed: 30,
		backDelay: 400,
		cursorChar: "&#9610;",
		fadeOut: true,
		onComplete: function(self) {
			self.cursor.remove();
		}
	});
});